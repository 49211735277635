import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet } from "../../../utils/API";

function Verify() {
  const { code } = useParams(); // Get code from URL if available
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({ code: code || "" }); // Pre-fill if code exists
  const [loading, setLoading] = useState(false);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitButton = async (e) => {
    e.preventDefault();
    if (!inputField.code.trim()) {
      toast("Please enter a verification code", false);
      return;
    }

    setLoading(true);
    try {
      const res = await fetchGet(`${API_PATH.SUBMIT_VERIFY}/${inputField.code}`);
      toast(res.message, res.success);

      if (res.success) {
        setTimeout(() => navigate("/signin"), 2000);
      }
    } catch (error) {
      toast("Something went wrong", false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row w-100 m-0 align-items-center">
      <div className="col-md-7 col-lg-5 m-h-auto">
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between m-b-30">
              <img className="img-fluid" alt="" src="assets/images/logo/logo.png" />
              <h3 className="m-b-0">Verify</h3>
            </div>
            <form onSubmit={submitButton}>
              <div className="d-flex align-items-center justify-content">
                Please enter the code sent to your email to verify:
              </div>
              <div className="form-group">
                <label className="font-weight-semibold" htmlFor="code">
                  Code:
                </label>
                <div className="input-affix">
                  <i className="prefix-icon anticon anticon-code" />
                  <input
                    type="text"
                    className="form-control"
                    name="code"
                    id="code"
                    onChange={inputsHandler}
                    placeholder="Enter Code"
                    value={inputField.code}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-between">
                  <button className={`btn btn-primary ${loading ? "is-loading" : ""}`} type="submit">
                    {loading ? <i className="anticon anticon-loading m-r-5"></i> : null}
                    <span>{loading ? "Verifying..." : "Submit"}</span>
                  </button>
                </div>
              </div>
            </form>
            {loading && <p className="text-center mt-3">Verifying, please wait...</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
