import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stringCurrency, toGlobalCurrency, toast } from "../../../utils/Helper";
import { API_PATH } from "../../../utils/Constant";
import { fetchGet } from "../../../utils/API";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import LoadingComponent from "../../../components/LoadingComponent";

const Dashboard = () => {
  const navigate = useNavigate();

  const [loadingScreen, setLoadingScreen] = useState(false);
  const [pieData, setPieData] = useState([
    { value: 0 },
    { value: 0 },
    { value: 0 },
  ]);
  const [lineChartMode, setmodeLineChart] = useState("number");
  const [lineData, setLineData] = useState({
    number: [],
    nominal: [],
  });
  const [cardInfo, setCardInfo] = useState({
    balance: [],
    transaction_amount: [],
    disbursement_request: 0,
    num_transaction: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoadingScreen(true);
      var res = await fetchGet(API_PATH.DASHBOARD);
      if (!res.success) {
        toast(res.message, res.success);
        if (res.status === 401) navigate("/signin", { state: { ...res } });
      } else {
        setCardInfo({
          balance: res.data.balance || [],
          transaction_amount: res.data.transaction_amount || [],
          disbursement_request: res.data.disbursement_request,
          num_transaction: res.data.num_transaction,
        });
        setPieData(res.data.transaction_pie);
        setLineData(res.data.transaction_line);
      }
      setLoadingScreen(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {loadingScreen && <LoadingComponent />}
      <div>
        <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="alert alert-warning p-4 rounded">
                    <h4 className="alert-heading">📢 Important Announcement</h4>
                    <p className="mb-2">
                      Please download the necessary forms below. Ensure you fill them out completely before submission.
                    </p>
                    <hr />
                    <div className="mt-2">
                      <a
                        href="https://api.motra.co.id/public/sample%20v4_PT_AAA__Formulir_KYC_Calon_Mitra%20-%205.pdf"
                        className="d-block fw-bold text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        📄 Download Sample Form KYC 
                      </a>
                      <a
                        href="https://api.motra.co.id/public/v4_PT_AAA__Formulir_KYC_Calon_Mitra.pdf"
                        className="d-block fw-bold text-primary mt-2"
                        download
                      >
                        📄 Download Official Form KYC
                      </a>
                    </div>
              </div>
            </div>
          {cardInfo.balance.map((item, key) => (
            <div className="col-md-6 col-lg-3" key={`balance-${key}`}>
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-gold">
                      <i className="anticon anticon-wallet" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">{toGlobalCurrency(item.value)}</h2>
                      <p className="m-b-0 text-black-50">{item.code} Balance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {cardInfo.transaction_amount.map((item, key) => (
            <div
              className="col-md-6 col-lg-3"
              key={`transaction-amount-${key}`}
            >
              <div className="card">
                <div className="card-body">
                  <div className="media align-items-center">
                    <div className="avatar avatar-icon avatar-lg avatar-cyan">
                      <i className="anticon anticon-dollar" />
                    </div>
                    <div className="m-l-15">
                      <h2 className="m-b-0">{toGlobalCurrency(item.value)}</h2>
                      <p className="m-b-0 text-black-50">
                        Total {item.code} Transaction Amount
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-blue">
                    <i className="anticon anticon-schedule" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">{cardInfo.disbursement_request}</h2>
                    <p className="m-b-0 text-black-50">Disbursment Request</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <div className="media align-items-center">
                  <div className="avatar avatar-icon avatar-lg avatar-purple">
                    <i className="anticon anticon-profile" />
                  </div>
                  <div className="m-l-15">
                    <h2 className="m-b-0">{cardInfo.num_transaction}</h2>
                    <p className="m-b-0 text-black-50">Transaction </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="m-b-0">Transactions</h5>
                <div className="text-center" style={{ height: 317 }}>
                  <ResponsivePie
                    data={pieData}
                    margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    colors={{ datum: "data.color" }}
                    borderColor={{ from: "color" }}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                  />
                </div>
                <div className="row border-top p-t-25">
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-warning badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[0].value}</h4>
                          <p className="m-b-0 muted">{pieData[0].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-success badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[1].value}</h4>
                          <p className="m-b-0 muted">{pieData[1].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="d-flex justify-content-center">
                      <div className="media align-items-center">
                        <span className="badge badge-danger badge-dot m-r-10" />
                        <div className="m-l-5">
                          <h4 className="m-b-0">{pieData[2].value}</h4>
                          <p className="m-b-0 muted">{pieData[2].label}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Transactions</h5>
                  <div>
                    <div className="btn-group">
                      <button
                        className={[
                          "btn btn-default ",
                          lineChartMode === "number" ? " active" : "",
                        ]}
                        onClick={() => setmodeLineChart("number")}
                      >
                        <span>Number</span>
                      </button>
                      <button
                        className={[
                          "btn btn-default ",
                          lineChartMode === "nominal" ? " active" : "",
                        ]}
                        onClick={() => setmodeLineChart("nominal")}
                      >
                        <span>Nominal</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="m-t-50" style={{ height: 330 }}>
                  {lineChartMode === "number" && (
                    <ResponsiveLine
                      data={lineData.number}
                      margin={{ top: 0, right: 100, bottom: 70, left: 80 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: true,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      curve="natural"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Time",
                        legendOffset: 36,
                        legendPosition: "middle",
                      }}
                      axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Number of transaction",
                        legendOffset: -70,
                        legendPosition: "middle",
                      }}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                  {lineChartMode === "nominal" && (
                    <ResponsiveLine
                      data={lineData.nominal}
                      margin={{ top: 0, right: 100, bottom: 70, left: 80 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: true,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      curve="natural"
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Time",
                        legendOffset: 36,
                        legendPosition: "middle",
                      }}
                      axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "Nominal of transaction",
                        legendOffset: -70,
                        legendPosition: "middle",
                      }}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
